<template>
  <VCol>
    <DataViewUI
      ref="dataView"
      card-height="150px"
      default-view-mode="grid"
      :data="data"
      :is-loading="activity.isLoading"
      :rows-per-page="[12, 24, 48, 96]"
      hide-main-title
      @paginate="onGetMore"
      @search="onSearch"
    >
      <template #card="props">
        <LessonHomeCard
          all
          saved
          :colors="colors"
          :item="props.item"
          @viewAssessment="onSetViewDialogModel"
          @deleteItem="onDeleteItem"
        />
      </template>
    </DataViewUI>
    <Dialog
      :max-width="$vuetify.breakpoint.mdAndUp ? '1100px' : '100%'"
      :is-dialog-open="dialogs.view"
      :title="currentModelTitleText"
      @toggle:dialog="onToggleViewDialog"
    >
      <ViewComponent
        :id="
          currentModel
            ? {
              id: currentModel.id,
              assessment_id: currentModel.assessment_id,
            }
            : null
        "
        :assessment-type="assessmentType"
        :title-text="currentModelTitleText"
        ref="viewItem"
        mode="dialog"
      />
    </Dialog>
    <Confirm ref="confirmDelete" />
  </VCol>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import SearchMixin from "@/mixins/Search";
import ViewComponent from "../ViewSaved";
import HasDueMixin from "@/mixins/HasDueOn";
import HasAssessmentsMixin from "@/mixins/HasAssessments";
import LessonHomeCard from "./Card";

export default {
  name: "AssessmentsSaved",
  props: {
    assessmentType: {
      type: String,
      default: "Lessons",
    },
    searchContent: {
      type: String,
      default: null,
    },
  },
  components: {
    LessonHomeCard,
    ViewComponent,
  },
  mixins: [SearchMixin, HasDueMixin, HasAssessmentsMixin],
  data() {
    return {
      modelType: "assessmentSaved",
      lastSearched: "",
    };
  },
  watch: {
    searchContent: {
      handler(value) {
        this.$refs.dataView.searchString = value;
      },
    },
  },
  created() {
    this.$on("searchStart", () => {
      this.data.data = [];
    });
  },
  computed: {
    featureType() {
      return this.featureName(this.assessmentType);
    },
    featureLowerCase() {
      return this.featureName(this.assessmentType).toLowerCase();
    },
    currentModelTitleText() {
      if (this.currentModel instanceof Object && this.currentModel.id) {
        return `Saved ${this.featureType.singularize()}: ${
          this.currentModel.assessment.title
        }`;
      }
      return `Saved ${this.featureType.singularize()}`;
    },
  },
  methods: {
    ...mapActions({
      doGetSaved: "assessmentSaved/getAll",
      doDeleteSaved: "assessmentSaved/delete",
    }),
    onGetAll(_params) {
      const params = _params || {};
      params.type = this.assessmentType
        .parameterize()
        .singularize()
        .toLowerCase();
      if (this.searchContent) {
        params.s = this.searchContent;
        this.lastSearched = this.searchContent;
      }
      return this.doGetSaved(params);
    },
    onDelete(params) {
      return this.doDeleteSaved(params);
    },
    searchSavedLessons(checkSearch = false) {
      if (checkSearch) {
        if (this.searchContent && this.searchContent !== this.lastSearched) {
          this.lastSearched = this.searchContent;
          this.$refs.dataView.loadData();
        } else if (this.searchContent == "" && this.lastSearched) {
          this.lastSearched = this.searchContent;
          this.$refs.dataView.loadData(true);
        }
      } else {
        this.$refs.dataView.searchString = this.searchContent;
        this.$refs.dataView.loadData();
      }
    },
    refreshSavedLessons() {
      this.$refs.dataView.loadData(true);
    },
  },
};
</script>
