<template>
  <VRow
    column
    :class="{
      'lessons-standard': true,
      'mx-0': $vuetify.breakpoint.mdAndUp,
      'px-4': $vuetify.breakpoint.smAndDown,
    }"
  >
    <VCol
      class="pl-6 pr-6"
      style="flex: 0 0 64px"
    >
      <IndexHeader
        mode="standalone"
        :can-create="$isUserAdmin || checkMentorPermission(modelType)"
        :route-name="modelTypeRoute"
      >
        <template #title>
          <VRow>
            <VCol class="pl-0">
              <h4 class="text-no-wrap">
                All {{ featureName(modelTypeTitle) }}
              </h4>
              <h6 v-if="isEnabledFeature('worksheets')">
                <router-link :to="{ name: `${modelTypeAlt}.index` }">
                  {{ modelTypeAltTitle.pluralize() }}
                </router-link>
              </h6>
            </VCol>
          </VRow>
        </template>
        <template #create-action>
          <ExpandableButton
            v-if="$isUserAdmin || checkMentorPermission(modelType)" 
            large
            flex
            height="38px"
            icon="add"
            button-class="wethrive px-5"
            button-style="display: grid;"
            style
            :block="$vuetify.breakpoint.smAndDown"
            :to="{
              name: `${modelType}.create`,
              params: {
                team: $team.slug,
              },
            }"
            :tooltip-text="`Add ${featureName(modelType.capitalize(), 'singularize')}`"
          />
        </template>
        <template #actions-left>
          <VTextField
            v-model="searchContent"
            solo
            dense
            hide-details
            prepend-inner-icon="search"
            append-icon="refresh"
            label="Search lesson..."
            @keyup.enter="searchLesson"
            @click:append="() => $refs.dataView.loadData(true)"
          />
          <VBtn
            height="38px"
            color="#66BB6A"
            class="ml-3"
            :block="$vuetify.breakpoint.smAndDown"
            :disabled="data.length === 0"
            @click.native="dialogs.reorder = true"
          >
            <VIcon
              color="white"
              size="16px"
            >
              mdi-format-list-bulleted-triangle
            </VIcon>
            <span
              class="pl-1 white--text"
              style="font-size: 14px;"
            >
              Reorder
            </span>
          </VBtn>
        </template>
      </IndexHeader>
    </VCol>
    <VCol
      class="px-2"
      style="flex: 1 1 auto"
    >
      <VRow
        row
        wrap
      >
        <VCol>
          <VTabs
            centered
            background-color="transparent"
            class="d-none"
          >
            <VTab
              v-for="(tab, key) in tabs"
              lazy
              :to="{
                hash: tab.url,
              }"
              :key="key"
            >
              <BetaBadge
                enable-tooltip
                :is-beta="isBetaFeature(tab.feature)"
              >
                {{ tab.title }}
              </BetaBadge>
            </VTab>
          </VTabs>
          <VTabsItems
            touchless
            :value="activeUrlTab"
          >
            <VTabItem>
              <CurrentFilter
                v-if="getQueryFilterValues.length > 0"
                class="px-4 mb-n2 mt-1"
                :filter="filter"
                :filter-values="getQueryFilterValues"
                :is-loading="activity.isLoading"
                @removeFilter="onRemoveFilter"
                @resetFilter="onResetFilter"
              />
              <DataViewUI
                ref="dataView"
                card-height="150px"
                default-view-mode="grid"
                hide-main-title
                :data="data"
                :is-loading="activity.isLoading"
                :card-color="getCardColor"
                :row-class="getCardColor"
                :card-class="getCardColor"
                :rows-per-page="[12, 24, 48, 96]"
                @paginate="onGetMore"
                @search="onSearch"
              >
                <template #card="props">
                  <LessonHomeCard
                    disable-visibility
                    :item="props.item"
                    :colors="colors"
                    :active="!props.item.is_locked"
                    :loading="props.item.isDuplicating"
                    :processing="props.item.processingDuplicate"
                    :visible-to="getVisibleTo(props.item)"
                    @toggleItem="onToggle"
                    @toggleLockItem="onToggleLock"
                    @deleteItem="onDeleteItem"
                    @viewSubmitted="onToggleSubmittedDialog"
                    @duplicateItem="(item) => onDuplicateItem(item, null, null, true, true)"
                  >
                    <template
                      v-if="$isUserAdmin"
                      #status-options
                    >
                      <VList class="pt-1">
                        <VListItem>
                          <VIcon
                            size="20px"
                            class="pr-2"
                          >
                            mdi-ballot-outline
                          </VIcon>
                          <VListItemTitle>
                            Status
                          </VListItemTitle>
                        </VListItem>
                      </VList>
                      <VDivider />
                      <VRadioGroup
                        hide-details
                        class="px-4 my-2"
                        :value="getLessonStatus(props.item)"
                      >
                        <VRadio
                          v-for="(option, index) in statusOptions"
                          :key="index"
                          color="success"
                          class="view-options pb-1"
                          :label="option.title"
                          :value="option.value"
                          @change="onStatusChange(props.item, option.value)"
                        />
                      </VRadioGroup>
                    </template>
                    <template
                      slot="menu-items"
                      slot-scope="{ previewOnly, isBackToDefault }"
                    >
                      <CardMenuItems
                        :item="props.item"
                        :preview-only="previewOnly"
                        :is-back-to-default="isBackToDefault"
                        @viewSubmitted="onToggleSubmittedDialog"
                        @duplicateItem="(item) => onDuplicateItem(item, null, null, true, true)"
                        @deleteItem="onDeleteItem"
                      />
                    </template>
                  </LessonHomeCard>
                </template>
              </DataViewUI>
              <FormDialog
                :fullscreen="true"
                :hide-loverlay="true"
                :current-model="currentModel"
                :is-dialog-open="dialogs.form"
                :title="currentModel ? currentModel.title : ''"
                :model-type="modelType"
                @toggleFormDialog="onToggleFormDialog"
                @deleteItem="onDeleteItem"
              >
                <Save
                  ref="saveForm"
                  mode="dialog"
                  :id="currentModel ? currentModel.id : null"
                />
              </FormDialog>
              <Confirm ref="confirmDelete" />
              <Dialog
                :is-dialog-open="dialogs.view"
                :fullscreen="true"
                :fill-height="true"
                :hide-overlay="true"
                :title="assessmentTitle"
                @title="(title) => (assessmentTitle = title)"
                @toggle:dialog="onToggleViewDialog"
              >
                <ViewComponent
                  :id="currentModel ? currentModel.id : null"
                  @title="(title) => (assessmentTitle = title)"
                  ref="viewItem"
                  mode="dialog"
                />
              </Dialog>
              <Dialog
                max-width="75%"
                :is-dialog-open="submittedDialogs.submitted"
                title="Submitted"
                @toggle:dialog="onToggleSubmittedDialog"
              >
                <Submitted
                  mode="dialog"
                  :assessment="submittedModel"
                />
              </Dialog>
              <ReorderDialog
                model-type="lessons"
                :is-dialog-open="dialogs.reorder"
                :get-items="getReorderItems"
                :reorder-action="(d) => onSaveReorder('lessons', d)"
                @toggle:dialog="onToggleReorderDialog"
              />
            </VTabItem>
            <VTabItem>
              <VRow column>
                <VCol
                  class="py-2"
                  style="flex: 1 1 auto"
                >
                  <VRow
                    v-if="data.data.length > 0"
                    row
                    wrap
                  >
                    <VCol cols="12">
                      <VAlert
                        type="info"
                        class="ma-6"
                        :value="true"
                      >
                        This is a preview version of Lessons.
                      </VAlert>
                      <VCard
                        flat
                        class="px-5"
                      >
                        <VCardText>
                          <VCol class="pl-0">
                            <h3 style="color: black;">
                              Overdue
                            </h3>
                          </VCol>
                          <VSheet
                            max-width="1270px"
                            class="group-slider"
                          >
                            <VSlideGroup
                              class="pt-4 pb-4"
                              show-arrows
                            >
                              <VSlideItem
                                v-for="item in data.data.slice(0, 1)"
                                :key="item.id"
                              >
                                <LessonHomeCard
                                  overdue
                                  preview-only
                                  margin-right="24px"
                                  :colors="colors"
                                  :item="item"
                                  :due-on="getDue(item)"
                                  :due-day="getDueDay(item)"
                                />
                              </VSlideItem>
                            </VSlideGroup>
                          </VSheet>
                        </VCardText>
                      </VCard>
                      <VDivider />
                      <VCard
                        flat
                        class="px-5"
                      >
                        <VCardText>
                          <VCol class="pl-0">
                            <h3 style="color: black;">
                              Started but not complete
                            </h3>
                          </VCol>
                          <VSheet
                            max-width="1270px"
                            class="group-slider"
                          >
                            <VSlideGroup
                              class="pt-4 pb-4"
                              show-arrows
                            >
                              <VSlideItem
                                v-for="item in data.data.slice(0, 1)"
                                :key="item.id"
                              >
                                <LessonHomeCard
                                  incompleted
                                  preview-only
                                  margin-right="24px"
                                  :colors="colors"
                                  :item="item"
                                  :due-on="getDue(item)"
                                  :due-day="getDueDay(item)"
                                />
                              </VSlideItem>
                            </VSlideGroup>
                          </VSheet>
                        </VCardText>
                      </VCard>
                      <VDivider />
                      <VCard
                        flat
                        class="px-5"
                      >
                        <VCardText>
                          <VCol class="pl-0">
                            <h3 style="color: black;">
                              All Lessons
                            </h3>
                          </VCol>
                          <VSheet
                            max-width="1270px"
                            class="group-slider"
                          >
                            <VSlideGroup
                              class="pt-4 pb-4"
                              show-arrows
                            >
                              <VSlideItem
                                v-for="item in data.data.slice(0, 1)"
                                :key="item.id"
                              >
                                <LessonHomeCard
                                  all
                                  preview-only
                                  margin-right="24px"
                                  :colors="colors"
                                  :item="item"
                                  :due-on="getDue(item)"
                                  :due-day="getDueDay(item)"
                                />
                              </VSlideItem>
                            </VSlideGroup>
                          </VSheet>
                        </VCardText>
                      </VCard>
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>
            </VTabItem>
            <VTabItem>
              <Export ref="exported" />
            </VTabItem>
          </VTabsItems>
        </VCol>
      </VRow>
      <Confirm
        ref="confirmDuplicate"
        confirm-color="info"
        cancel-color="disabled"
      />
    </VCol>
  </VRow>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasStatsMixin from "@/mixins/HasStats";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import HasAssessmentsMixin from "@/mixins/HasAssessments";
import HasDueMixin from "@/mixins/HasDueOn";
import Save from "../Save";
import ViewComponent from "../View";
import Submitted from "../Submitted/index";
import { mapActions } from "vuex";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";
import ReorderDialog from "@/components/Elements/Dialogs/ReorderDialog";
import VisibleToMixin from "@/mixins/VisibleTo";
import LessonHomeCard from "./Card";
import CardMenuItems from "./MenuItems";
import Export from "@/components/Lessons/Export";
import HasTabsMixin from "@/mixins/HasTabs";

export default {
  name: "LessonsAdmin",
  components: {
    Save,
    ViewComponent,
    Submitted,
    IndexHeader,
    LessonHomeCard,
    CardMenuItems,
    ReorderDialog,
    Export,
  },
  mixins: [
    SearchMixin,
    HasTabsMixin,
    HasStatsMixin,
    HasSubmittedDataMixin,
    HasAssessmentsMixin,
    HasDueMixin,
    VisibleToMixin,
  ],
  data() {
    return {
      date: null,
      assessmentTitle: "",
      modelType: "lesson",
      modelTypeAlt: "worksheet",
      statOptions: {
        inflect: {
          worksheets: {
            plural: "Worksheets",
            singular: "Worksheet",
          },
        },
      },
      reorderData: { data: [] },
      searchContent: null,
      dialogs: {
        reorder: false,
      },
    };
  },
  computed: {
    legend() {
      return [
        {
          title: "Inactive",
          tooltip: "This item is not active and is only visible to admins",
          color: this.colors.background.default,
          textColor: this.colors.font.black,
        },
        {
          title: `${this.featureName("Students")}`,
          tooltip: `This item is active and visible to everyone`,
          color: this.colors.background.success,
          textColor: this.colors.font.white,
        },
        {
          title: `${this.featureName("Mentors")}`,
          tooltip: `This item is active and unlocked so it is NOT visible to ${this.featureName(
            "students"
          )}`,
          color: this.colors.background.warning,
          textColor: this.colors.font.white,
        },
        {
          title: `${this.featureName("Mentors")}`,
          tooltip: `This item is active and locked so it is NOT visible to ${this.featureName(
            "students"
          )}`,
          color: this.colors.background.info,
          textColor: this.colors.font.white,
        },
        {
          title: "Admins Only",
          tooltip: `This item is active and unlocked but isn't configured to be visible to ${this.featureName(
            "mentors"
          )} or ${this.featureName("students")}`,
          color: this.colors.background.error,
          textColor: this.colors.font.white,
        },
      ];
    },
    tabs() {
      return [
        {
          title: this.featureName("Lessons"),
          url: "",
          enabled: true,
        },
        {
          title: "Preview",
          url: "#preview",
          enabled: true,
        },
        {
          title: `Export Results`,
          url: "#export",
          enabled: true,
          feature: "lesson_results_export",
        },
      ].filter((t) => t.enabled);
    },
  },
  watch: {
    searchContent: {
      handler(value) {
        this.$refs.dataView.searchString = value;
      },
    },
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "lesson/getIndexConfig",
      doGetAll: "lesson/getAll",
      doGetOne: "lesson/getOne",
      doDelete: "lesson/delete",
      doToggle: "lesson/toggle",
      doToggleLocked: "lesson/toggleLocked",
      doSaveSortRelation: "team/sortRelation",
      doDuplicateLesson: "lesson/duplicate",
    }),
    onDelete(lesson) {
      return this.doDelete(lesson.id);
    },
    onDuplicate(params) {
      return this.doDuplicateLesson(params);
    },
    onGet(id) {
      return this.doGetOne(id).then((result) => {
        this.setDueOn(result);
        result.cards = result.cards || [];
        return result;
      });
    },
    onGetAll(params) {
      params.sort = params.sort || "priority";
      return this.doGetAll(params).then((result) => {
        // Handled duplication loading card
        if(!params.hasOwnProperty("page") || (params.hasOwnProperty("page") && params.page == 1)) {
          const pendingDuplications = this.getGroupItemLocalStorage("pending-duplications", this.modelType);
          if(pendingDuplications && Object.keys(pendingDuplications).length > 0) {
            Object.keys(pendingDuplications).map((itemKey) => {
              const itemIndex = result.data.findIndex((item) => item.duplicated_using_id == itemKey.split("-")[0]);
              if(itemIndex > -1) {
                this.$delete(pendingDuplications, itemKey);
                this.removeGroupItemLocalStorage("pending-duplications", this.modelType, itemKey);
              }
            });
          }
          this.$set(result, "data", [
            ...Object.values(pendingDuplications),
            ...result.data,
          ]);
        }
        return result
      });
    },
    onToggle(id) {
      const index = this.data.data.findIndex(
        (d) => d.id === (id instanceof Object ? id.id : id)
      );
      if (index > -1) {
        this.$set(this.data.data[index], "isLoading", true);
        this.onToggleLocal(id).then((result) => {
          this.$set(this.data.data[index], "isLoading", false);
          // this.$set(this.data.data[index], "is_locked", result.is_locked);
        });
      } else {
        this.$log.debug("[Lessons Index]: Missing lesson", id);
      }
    },
    onToggleLock(id) {
      const index = this.data.data.findIndex(
        (d) => d.id === (id instanceof Object ? id.id : id)
      );
      if (index > -1) {
        this.$set(this.data.data[index], "isLoading", true);
        this.onToggleLockLocal(id).then((result) => {
          this.$set(this.data.data[index], "isLoading", false);
          // this.$set(this.data.data[index], "is_locked", result.is_locked);
        });
      } else {
        this.$log.debug("[Lessons Index]: Missing lesson", id);
      }
    },
    getContentType(lesson) {
      let types = [];
      if (lesson.programs && lesson.programs.length) {
        types.push(this.featureName("Programs"));
      }
      if (lesson.supplements && lesson.supplements.length) {
        types.push(this.featureName("Supplements"));
      }
      if (lesson.primary_contents && lesson.primary_contents.length) {
        types.push(this.featureName("Content"));
      }
      return types.join(", ");
    },
    getCardColor(card) {
      const color = {
        "white--text": this.getCardHasWhiteText(card),
      };
      if (
        card.is_active &&
        !card.is_locked &&
        (!card.visible_to ||
          (card.visible_to instanceof Object &&
            !Object.values(card.visible_to).length))
      ) {
        color["red lighten-2"] = true;
      } else if (card.is_locked) {
        color["orange lighten-2"] = true;
      } else if (card.is_active) {
        color["green lighten-2"] = true;
      } else {
        color["grey lighten-1"] = true;
      }
      return color;
    },
    getButtonColor(card) {
      const color = {
        "white--text": this.getCardHasWhiteText(card),
      };
      if (card.is_locked) {
        color["warning"] = true;
      } else if (card.is_active) {
        color["success"] = true;
      } else {
        color["default"] = true;
      }
      return color;
    },
    getCardHasWhiteText(card) {
      return card.is_locked || card.is_active ? true : false;
    },
    searchLesson() {
      this.$refs.dataView.searchString = this.searchContent;
      this.$refs.dataView.loadData();
    },
    getReorderItems() {
      return this.doGetAll({ forPrioritization: 1 });
    },
    async onSaveReorder(relationType, data) {
      let params = {
        id: this.$team.id,
        relation: relationType,
      };
      params.items = data.map((item, i) => {
        return {
          id: item.id,
          priority: i + 1,
        };
      });
      await this.doSaveSortRelation(params).then((result) => {
        this.reorderData.data = result;
        this.$bus.$emit(
          "notificationMessage",
          `Successfully ${relationType.capitalize()} reordered`
        );
      });
      return this.reorderData.data;
    },
    onToggleReorderDialog() {
      this.dialogs.reorder = !this.dialogs.reorder;
      //Reload the lessons if the dialog was closed
      if (!this.dialogs.reorder) {
        this.onSearch(this.filter || {});
      }
    },
    onBack() {
      if(this.$route.params && this.$route.params.isBackToDefault) {
        this.$router.go(-1);
      } else {
        this.navigateTo('dashboard');
      }
    },
  },
};
</script>
<style lang="scss">
.lessons-standard {
  max-width: 100%;

  .v-text-field .v-label {
    font-size: 13px;
  }
  .v-text-field .v-input__icon .v-icon {
    font-size: 20px;
  }
  .v-input__control > .v-input__slot {
    border-radius: 5px !important;
  }
  .active-tab {
    background-color: #ebebeb;
  }
  .v-tabs-bar {
    padding: 0 !important;
  }
  .v-tabs-items {
    top: -6px;
    border-radius: 3px;
    min-height: 250px;
  }
  .v-tabs-bar__content {
    padding: 0px !important;
    background-color: transparent !important;
    height: auto !important;
  }
  .v-tabs-slider-wrapper .v-tabs-slider {
    border-radius: 5px !important;
  }
}
</style>
