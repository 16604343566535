<script>
import { mapActions } from "vuex";
import Base from "./Admin";

export default {
  name: "WorksheetsAdmin",
  extends: Base,
  data() {
    return {
      modelType: "worksheet",
      modelTypeAlt: "lesson",
    };
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "worksheet/getIndexConfig",
      doGetAll: "worksheet/getAll",
      doGetOne: "worksheet/getOne",
      doDelete: "worksheet/delete",
      doGetSubmitted: "worksheetSubmitted/getAll",
      doToggle: "worksheet/toggle",
    }),
  },
};
</script>

