<template>
  <VRow column>
    <WorksheetsAdmin
      v-if="($isUserAdmin || $isUserMentor) && $route.name == 'worksheet.index' && isEnabledFeature('worksheets')"
    />
    <LessonsAdmin v-else-if="$isUserAdmin || $isUserMentor" />
    <LessonsNormal v-else />
  </VRow>
</template>

<script>
import LessonsAdmin from "./Index/Admin";
import WorksheetsAdmin from "./Index/WorksheetsAdmin";
import LessonsNormal from "./Index/Normal";

export default {
  name: "LessonIndex",
  components: {
    LessonsAdmin,
    LessonsNormal,
    WorksheetsAdmin,
  },
};
</script>
