<template>
  <VContainer
    grid-list-lg
    class="pa-0"
  >
    <VRow
      v-if="modelHasId"
      column
    >
      <VCol
        v-if="mode === 'standalone'"
        style="flex: 0 0 64px"
      >
        <VRow
          row
          justify-space-between
        >
          <h1>
            <VBtn
              icon
              fab
              @click="$router.go(-1)"
            >
              <VIcon>arrow_back</VIcon>
            </VBtn>
            Saved {{ featureName(type) }}
          </h1>
        </VRow>
      </VCol>
      <VCol
        class="pa-0"
        style="flex: 1 1 auto"
      >
        <VRow>
          <VCol
            v-if="mode === 'standalone'"
            cols="12"
            style="height: 64px"
          >
            <VRow
              row
              align-center
            >
              <VBtn
                icon
                large
                @click="$router.go(-1)"
              >
                <VIcon>arrow_back</VIcon>
              </VBtn>
              <h1>
                <VIcon color="yellow">
                  star
                </VIcon>
                {{ titleText }}
              </h1>
            </VRow>
          </VCol>
          <VCol
            v-if="cards.length"
            cols="12"
          >
            <VCarousel
              v-model="currentIndex"
              height="auto"
              :cycle="false"
              :show-arrows="cards.length > 1"
              :hide-delimiters="true"
            >
              <VCarouselItem
                v-for="(card,key) in cards"
                :key="key"
                reverse-transition="slide"
                transition="slide"
              >
                <Card
                  mode="preview"
                  :card="card"
                  :index="key"
                  :is-first="key === 0"
                  :is-last="key === cards.length-1"
                  :assessment="model"
                  @next="next"
                  @previous="previous"
                />
              </VCarouselItem>
            </VCarousel>
          </VCol>
          <VCol
            cols="12"
            class="ml-2"
            v-if="hasAnyAssociations"
          >
            <template v-if="hasKeyPoints">
              <h2>
                <VIcon color="primary">
                  >key
                </VIcon>Key Points
              </h2>
              <VList single-line>
                <VListItem
                  v-for="(item, key) in model.key_points"
                  :key="key"
                >
                  <VListItemAvatar>{{ key++ }}</VListItemAvatar>
                  <VListItemTitle>{{ item.value }}</VListItemTitle>
                </VListItem>
              </VList>
            </template>
            <template v-if="hasDoNow">
              <h2>
                <VIcon color="red">
                  >info
                </VIcon>Do Now
              </h2>
              <VList single-line>
                <VListItem
                  v-for="(item, key) in model.do_now"
                  :key="key"
                >
                  <VListItemTitle>{{ item.value }}</VListItemTitle>
                </VListItem>
              </VList>
            </template>
          </VCol>
        </VRow>
      </VCol>
    </VRow>
    <VRow
      v-else
      row
      fluid
      justify-center
      class="mt-4"
    >
      <VProgressCircular
        indeterminate
        color="primary"
      />
    </VRow>
  </VContainer>
</template>

<script>
import { mapActions } from "vuex";
import ViewMixin from "@/mixins/View";
import Card from "@/components/Lessons/Present/Card";

export default {
  name: "SavedAssessmentView",
  props: {
    type: {
      type: String,
      default: "Lessons"
    },
    titleText: {
      type: String,
      default: null
    }
  },
  components: { Card },
  mixins: [ViewMixin],
  data() {
    return {
      currentIndex: 0,
      modelType: "Saved Assessment"
    };
  },
  computed: {
    hasAnyAssociations() {
      return this.hasDoNow || this.hasKeyPoints;
    },
    hasDoNow() {
      return this.model && this.model.program instanceof Array;
    },
    hasKeyPoints() {
      return this.model && this.model.key_points instanceof Array;
    },
    cards() {
      return this.model.assessment.cards_saved_for_user instanceof Array
        ? this.model.assessment.cards_saved_for_user
        : [this.model.card];
    }
  },
  methods: {
    ...mapActions({
      doGetSavedAssessment: "assessmentSaved/getOne"
    }),
    onGet(id) {
      return this.doGetSavedAssessment(id);
    },
    next() {
      if (this.currentIndex < this.cards.length) {
        this.currentIndex++;
      }
    },
    previous() {
      if (this.currentIndex > 0) {
        this.currentIndex--;
      }
    }
  }
};
</script>

