<template>
  <VRow
    column
    :class="{
      'lessons-normal': true,
      'px-0': $vuetify.breakpoint.mdAndUp,
      'px-4': $vuetify.breakpoint.smAndDown,
    }"
  >
    <VCol
      class="px-6"
      style="flex: 0 0 64px"
    >
      <IndexHeader
        mode="standalone"
        :can-create="$isUserAdmin"
        :route-name="modelTypeRoute"
      >
        <template #title>
          <VRow>
            <VCol class="pl-0">
              <h4 class="text-no-wrap">
                {{ featureName(modelTypeTitle) }}
              </h4>
            </VCol>
          </VRow>
        </template>
        <template #actions-left>
          <VCol
            class="px-0 d-none"
            style="margin-top: 18px;"
          >
            <VTabs
              centered
              grow
              background-color="transparent"
            >
              <VTab
                :to="{
                  hash: '#',
                }"
              >
                All
              </VTab>
              <VTab
                :to="{
                  hash: '#saved',
                }"
              >
                Saved Lessons
              </VTab>
            </VTabs>
          </VCol>
        </template>
        <template #create-action />
        <template #actions-right>
          <VCol
            v-if="false"
            class="pl-0 mt-6"
          >
            <VTextField
              prepend-inner-icon="mdi-forum-outline"
              label="3 Open Chats Threads"
              solo
              dense
              readonly
            />
          </VCol>
          <VCol class="pl-0 mt-6">
            <VTextField
              v-model="searchContent"
              prepend-inner-icon="search"
              append-icon="refresh"
              label="Search lesson..."
              solo
              dense
              @keyup.enter="searchLesson"
              @click:append="refreshLessons"
            />
          </VCol>
        </template>
      </IndexHeader>
    </VCol>
    <VCol
      class="px-2"
      style="flex: 1 1 auto"
    >
      <VRow
        row
        wrap
      >
        <VCol>
          <VTabsItems
            touchless
            :value="activeUrlTab"
          >
            <VTabItem>
              <VCol class="pa-0 pt-4">
                <DataViewUI
                  ref="dataView"
                  card-height="150px"
                  default-view-mode="slider"
                  :data="data"
                  :is-loading="activity.isLoading"
                  :card-color="getCardColor"
                  :row-class="getCardColor"
                  :card-class="getCardColor"
                  :rows-per-page="[12, 24, 48, 96]"
                  hide-main-title
                  hide-records-info
                  @paginate="onGetMore"
                  @search="onSearch"
                >
                  <template #content="props">
                    <template v-if="isGrouped">
                      <VCard
                        v-if="props.items[0].overdue.data.length"
                        flat
                      >
                        <VCardText>
                          <VRow
                            max-width="1270px"
                            class="group-slider"
                          >
                            <VCol
                              cols="12"
                              class="pl-0"
                            >
                              <h3 style="color: black;">
                                Overdue
                              </h3>
                            </VCol>
                            <VCol
                              v-for="item in props.items[0].overdue.data"
                              sm="12"
                              :md="$isMenuExpanded ? 6 : 4"
                              :lg="$isMenuExpanded ? 4 : 3"
                              :key="item.id"
                            >
                              <LessonHomeCard
                                overdue
                                :colors="colors"
                                :item="item"
                                :due-on="getDue(item)"
                                :due-day="getDueDay(item)"
                              />
                            </VCol>
                          </VRow>
                        </VCardText>
                      </VCard>
                      <VDivider
                        v-if="
                          props.items[0].overdue.data.length &&
                            (props.items[0].started_not_complete.data.length || props.items[0].all.data.length)
                        "
                      />
                      <VCard
                        v-if="props.items[0].started_not_complete.data.length"
                        flat
                      >
                        <VCardText>
                          <VRow
                            max-width="1270px"
                            class="group-slider"
                          >
                            <VCol
                              cols="12"
                              class="pl-0"
                            >
                              <h3 style="color: black;">
                                Started but not complete
                              </h3>
                            </VCol>
                            <VCol
                              v-for="item in props.items[0].started_not_complete.data"
                              sm="12"
                              :md="$isMenuExpanded ? 6 : 4"
                              :lg="$isMenuExpanded ? 4 : 3"
                              :key="item.id"
                            >
                              <LessonHomeCard
                                incompleted
                                :colors="colors"
                                :item="item"
                                :due-on="getDue(item)"
                                :due-day="getDueDay(item)"
                              />
                            </VCol>
                          </VRow>
                        </VCardText>
                      </VCard>
                      <VDivider
                        v-if="
                          props.items[0].started_not_complete.data.length &&
                            props.items[0].all.data.length
                        "
                      />
                      <VCard
                        v-if="props.items[0].all.data.length"
                        flat
                      >
                        <VCardText>
                          <VRow
                            max-width="1270px"
                            class="group-slider"
                          >
                            <VCol
                              cols="12"
                              class="pl-0"
                            >
                              <h3 style="color: black;">
                                All Lessons
                              </h3>
                            </VCol>
                            <VCol
                              v-for="item in props.items[0].all.data"
                              sm="12"
                              :md="$isMenuExpanded ? 6 : 4"
                              :lg="$isMenuExpanded ? 4 : 3"
                              :key="item.id"
                            >
                              <LessonHomeCard
                                all
                                :colors="colors"
                                :item="item"
                                :due-on="getDue(item)"
                                :due-day="getDueDay(item)"
                              />
                            </VCol>
                          </VRow>
                        </VCardText>
                      </VCard>
                    </template>
                    <template v-else>
                      <VRow>
                        <VCol
                          v-for="item in props.items[0].data"
                          sm="12"
                          class="mb-6"
                          :md="$isMenuExpanded ? 6 : 4"
                          :lg="$isMenuExpanded ? 4 : 3"
                          :key="item.id"
                        >
                          <LessonHomeCard
                            all
                            :colors="colors"
                            :item="item"
                            :due-on="getDue(item)"
                            :due-day="getDueDay(item)"
                          />
                        </VCol>
                      </VRow>
                    </template>
                  </template>
                </DataViewUI>
              </VCol>
              <FormDialog
                :current-model="currentModel"
                :is-dialog-open="dialogs.form"
                :title="currentModel ? currentModel.title : ''"
                @toggleFormDialog="onToggleFormDialog"
                @deleteItem="onDeleteItem"
                :model-type="modelType"
                :fullscreen="true"
                :hide-loverlay="true"
              >
                <Save
                  ref="saveForm"
                  mode="dialog"
                  :id="currentModel ? currentModel.id : null"
                />
              </FormDialog>
              <Confirm ref="confirmDelete" />
              <Dialog
                :is-dialog-open="dialogs.view"
                :fullscreen="true"
                :fill-height="true"
                :hide-overlay="true"
                :title="assessmentTitle"
                @title="(title) => (assessmentTitle = title)"
                @toggle:dialog="onToggleViewDialog"
              >
                <ViewComponent
                  :id="currentModel ? currentModel.id : null"
                  @title="(title) => (assessmentTitle = title)"
                  ref="viewItem"
                  mode="dialog"
                />
              </Dialog>
              <Dialog
                max-width="75%"
                :is-dialog-open="submittedDialogs.submitted"
                title="Submitted"
                @toggle:dialog="onToggleSubmittedDialog"
              >
                <Submitted
                  mode="dialog"
                  :assessment="submittedModel"
                />
              </Dialog>
            </VTabItem>
            <VTabItem>
              <Saved
                ref="savedLesson"
                :assessment-type="modelType.titleize()"
                :search-content="searchContent"
              />
            </VTabItem>
          </VTabsItems>
        </VCol>
      </VRow>
    </VCol>
  </VRow>
</template>

<script>
import SearchMixin from "@/mixins/Search";
import HasStatsMixin from "@/mixins/HasStats";
import HasSubmittedDataMixin from "@/mixins/HasSubmittedData";
import HasAssessmentsMixin from "@/mixins/HasAssessments";
import HasDueMixin from "@/mixins/HasDueOn";
import Save from "../Save";
import ViewComponent from "../View";
import Submitted from "../Submitted/index";
import Saved from "./Saved";
import { mapActions } from "vuex";
import IndexHeader from "@/components/Elements/Navigation/IndexHeader";
import VisibleToMixin from "@/mixins/VisibleTo.vue";
import LessonHomeCard from "./Card";

export default {
  name: "LessonsStandard",
  components: {
    Save,
    ViewComponent,
    Submitted,
    Saved,
    IndexHeader,
    LessonHomeCard,
  },
  mixins: [
    SearchMixin,
    HasStatsMixin,
    HasSubmittedDataMixin,
    HasAssessmentsMixin,
    HasDueMixin,
    VisibleToMixin,
  ],
  data() {
    return {
      date: null,
      assessmentTitle: "",
      modelType: "lesson",
      modelTypeAlt: "worksheet",
      statOptions: {
        inflect: {
          worksheets: {
            plural: "Worksheets",
            singular: "Worksheet",
          },
        },
      },
      searchContent: null,
      tabs: [
        {
          title: "Lessons",
          url: "",
          enabled: true,
        },
        {
          title: "Saved",
          url: "saved",
          enabled: true,
        },
      ],
    };
  },
  computed: {
    isGrouped() {
      return this.data.data instanceof Array ? false : true;
    },
  },
  created() {
    this.onRouteHashChanged(this.$route.hash);
  },
  watch: {
    "$route.hash": function(value) {
      this.onRouteHashChanged(value);
      this.checkActiveTab();
    },
    searchContent: {
      handler(value) {
        if (!this.activeUrlTab) {
          this.$refs.dataView.searchString = value;
        }
      },
    },
  },
  methods: {
    ...mapActions({
      doGetIndexConfig: "lesson/getIndexConfig",
      doGetAll: "lesson/getAll",
      doGetOne: "lesson/getOne",
      doDelete: "lesson/delete",
      doToggle: "lesson/toggle",
    }),
    onRouteHashChanged(value) {
      switch (value) {
        case "#all-completed":
          this.onToggleSubmittedDialog({
            id: "all",
          });
          break;
      }
    },
    onDelete(lesson) {
      return this.doDelete(lesson.id);
    },
    onGet(id) {
      return this.doGetOne(id).then((result) => {
        this.setDueOn(result);
        result.cards = result.cards || [];
        return result;
      });
    },
    onGetAll(params) {
      params.group = true;
      return this.doGetAll(params);
    },
    onToggle(id) {
      const index = this.data.data.findIndex(
        (d) => d.id === (id instanceof Object ? id.id : id)
      );
      if (index > -1) {
        this.onToggleLocal(id).finally((result) => {
          this.$set(this.data.data[index], "isLoading", false);
        });
      } else {
        this.$log.debug("[Lessons Index]: Missing lesson", id);
      }
    },
    getContentType(lesson) {
      let types = [];
      if (lesson.programs && lesson.programs.length) {
        types.push(this.featureName("Programs"));
      }
      if (lesson.supplements && lesson.supplements.length) {
        types.push(this.featureName("Supplements"));
      }
      if (lesson.primary_contents && lesson.primary_contents.length) {
        types.push(this.featureName("Content"));
      }
      return types.join(", ");
    },
    getCardColor(card) {
      const color = {
        "white--text": this.getCardHasWhiteText(card),
      };
      if (
        card.is_active &&
        !card.is_locked &&
        (!card.visible_to ||
          (card.visible_to instanceof Object &&
            !Object.values(card.visible_to).length))
      ) {
        color["red lighten-2"] = true;
      } else if (card.is_locked) {
        color["orange lighten-2"] = true;
      } else if (card.is_active) {
        color["green lighten-2"] = true;
      } else {
        color["grey lighten-1"] = true;
      }
      return color;
    },
    getButtonColor(card) {
      const color = {
        "white--text": this.getCardHasWhiteText(card),
      };
      if (card.is_locked) {
        color["warning"] = true;
      } else if (card.is_active) {
        color["success"] = true;
      } else {
        color["default"] = true;
      }
      return color;
    },
    getCardHasWhiteText(card) {
      return card.is_locked || card.is_active ? true : false;
    },
    searchLesson() {
      if (this.activeUrlTab) {
        this.$refs.savedLesson.searchSavedLessons();
      } else {
        this.$refs.dataView.searchString = this.searchContent;
        this.$refs.dataView.loadData();
      }
    },
    refreshLessons() {
      if (this.activeUrlTab) {
        this.$refs.savedLesson.refreshSavedLessons();
      } else {
        this.$refs.dataView.loadData(true);
      }
    },
    checkActiveTab() {
      if (this.activeUrlTab) {
        this.$refs.savedLesson.searchSavedLessons(true);
      }
    },
  },
};
</script>
<style lang="scss">
.lessons-normal {
  .v-text-field .v-label {
    font-size: 13px;
  }
  .v-text-field .v-input__icon .v-icon {
    font-size: 20px;
  }
  .v-input__control > .v-input__slot {
    border-radius: 5px !important;
  }
  .active-tab {
    background-color: #ebebeb;
  }
  .v-tabs-bar {
    padding-top: 0 !important;
    padding-right: 0px !important;
  }
  .v-tabs-bar__content {
    margin: 0 !important;
    border-radius: 5px !important;
    height: 38px !important;
    width: 224px !important;
  }
  .v-tabs-items {
    border-radius: 3px;
    min-height: 250px;
  }
  .group-slider .v-slide-group__wrapper {
    padding: 8px 0;
  }
  .group-slider .v-slide-group__next,
  .v-slide-group__prev {
    min-width: 42px !important;
  }
}
</style>
